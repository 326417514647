// Twitter Bootstrap modal
// import 'bootstrap/js/dist/modal';
const Flickity = require('flickity');
// const Cookies = require('js-cookie');
// Voorbeeld voor component includen:
//import './components/component.js';

var Parsley = require('parsleyjs');

var objectFitImages = require('object-fit-images');

Parsley.addMessages('nl', {
  defaultMessage: "Deze waarde lijkt onjuist.",
  type: {
    email:        "Dit lijkt geen geldig e-mail adres te zijn.",
    url:          "Dit lijkt geen geldige URL te zijn.",
    number:       "Deze waarde moet een nummer zijn.",
    integer:      "Deze waarde moet een nummer zijn.",
    digits:       "Deze waarde moet numeriek zijn.",
    alphanum:     "Deze waarde moet alfanumeriek zijn."
  },
  notblank:       "Deze waarde mag niet leeg zijn.",
  required:       "Dit veld is verplicht.",
  pattern:        "Deze waarde lijkt onjuist te zijn.",
  min:            "Deze waarde mag niet lager zijn dan %s.",
  max:            "Deze waarde mag niet groter zijn dan %s.",
  range:          "Deze waarde moet tussen %s en %s liggen.",
  minlength:      "Deze tekst is te kort. Deze moet uit minimaal %s karakters bestaan.",
  maxlength:      "Deze waarde is te lang. Deze mag maximaal %s karakters lang zijn.",
  length:         "Deze waarde moet tussen %s en %s karakters lang zijn.",
  equalto:        "Deze waardes moeten identiek zijn."
});

Parsley.setLocale('nl');


(function($) {

    objectFitImages();

    $('.notice').on('click', function(){
        $('.notice').hide();
    });

    $('input[name="paymentmethod"]').on('change', function(){
        const value = $(this).val();
        $(document).find('[data-option]').addClass('hidden');
        $(document).find('[data-option="'+value+'"]').removeClass('hidden');
    });

	const airsensor = {};

	airsensor.hamburgerMenu = () => {
		$('.hamburger').on('click', function(){
			$('body').toggleClass('menu-active');

			$(this).toggleClass('is-active');
			$('.nav-mobile').toggleClass('open');
		});
	}
	airsensor.faq = () => {

		// const allAnswers = $('.answer').hide();

		$('.question').on('click', function(){

			$currentItem = $(this).parents('.faq-item');
			// allAnswers.slideUp();
			// $($currentItem).find('.answer').show();

			if (!$currentItem.hasClass('faq-item--open')){
				$('.faq-item').each(function(){
					$(this).removeClass('faq-item--open');
				});
				$currentItem.addClass('faq-item--open');
			} else {
				$currentItem.removeClass('faq-item--open');
			}
		});
	}

	airsensor.productCarousel  = () => {

		let $productCarousel = $('.carousel');
		$productCarousel.each(function(i, e) {
			new Flickity(e, {
				pageDots: false,
				draggable: true,
				wrapAround: false,
				cellAlign: 'center',
				watchCSS: true,
				prevNextButtons: false,
				initialIndex: '.is-initial-select'
			});
		});
    }

    airsensor.productAmount  = () => {
        $('.product-card .add').on('click', function(e){
            e.preventDefault();
            const $parent = $(this).parents('.product-card');
            const $input = $(this).siblings('input[type="number"]');
            let amount = parseInt($input.val());
            $input.val(amount + 1);
            $(this).siblings('.subtract').attr('disabled', false);
            calculatePrice($parent);
        });

        $('.product-card .subtract').on('click', function(e){
            e.preventDefault();
            const $parent = $(this).parents('.product-card');
            const $input = $(this).siblings('input[type="number"]');
            let amount = parseInt($input.val());
            let newAmount = amount - 1;
            if(amount > 1) {
                $input.val(amount - 1);
            }
            if(newAmount == 1) {
                $(this).attr('disabled', true);
            }
            calculatePrice($parent);
        });

        $('.product-card input').on('keyup', function(e){
            const $parent = $(this).parents('.product-card');
            if($(this).val().length > 0 ){
                if($(this).val() < 1) {
                    $(this).val(1);
                    $(this).siblings('.subtract').attr('disabled', true);
                } else {
                    $(this).siblings('.subtract').attr('disabled', false);
                }
            }
            calculatePrice($parent);
        });

        function calculatePrice($parentForm) {
            const $input = $parentForm.find('input[type="number"]');
            const normalPrice = $parentForm.find('.product__amount').data('normal');
            const extraPrice = $parentForm.find('.product__amount').data('extra');
            const $priceField = $parentForm.find('.price span');
            if($input.val() == 1) {
                $priceField.text(new Intl.NumberFormat('nl-NL', {minimumFractionDigits: 2}).format(normalPrice));
            } else {
                let extra = extraPrice * ($input.val() - 1);
                $priceField.text(new Intl.NumberFormat('nl-NL', {minimumFractionDigits: 2}).format(normalPrice + extra));
            }
        }
    }

	airsensor.pricesBtn  = () => {
		$(document).on('click', '.btn--prices-js', function(e) {
			if (
				location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
				&&
				location.hostname == this.hostname
			) {
				let target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {
					e.preventDefault();
					$('html, body').animate({
						scrollTop: target.offset().top
					}, 1000, function() {
						let $target = $(target);
						$target.focus();

						if ($target.is(":focus")) {
							return false;
						} else {
							$target.attr('tabindex','-1');
							$target.focus();
						};
					});
				}
			}
		});

	}

	airsensor.youtubeVideo = () => {

		const tag = document.createElement('script');

		tag.src = "https://www.youtube.com/iframe_api";

		const firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		const el = document.body.querySelector("iframe");

		if(!el) {
			return;
		}

		window.onYouTubeIframeAPIReady = function () {
			player = new YT.Player( el, {
				events: {
					'onReady': onPlayerReady,
					'onStateChange': onPlayerStateChange
				}
			});
		}

		function onPlayerReady(event) {
			$(document).on('click', '.js_play-button', function (e) {
				e.preventDefault();
				event.target.playVideo();
			});
		}

		function onPlayerStateChange(event) {
			if ((event.data === 1 ) || (event.data === -1 ) || (event.data === 3 ))  {
				$('.js_video-parent').addClass('js_video-playing');
		   	} else {
				$('.js_video-parent').removeClass('js_video-playing');
			}
		}
	}

	airsensor.init = function() {
		airsensor.hamburgerMenu();
		airsensor.faq();
		airsensor.pricesBtn();
		airsensor.youtubeVideo();
        airsensor.productCarousel();
        airsensor.productAmount();
	}

	airsensor.init();

	$(document).ready(function(){
		$('body').trigger('bless');
	});

})(jQuery);
